

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import userReducer from './userSlice.ts';
import { fableboxApi } from './services/fablebox/api.ts';


export const store = configureStore({
  reducer: {
    user: userReducer,
    [fableboxApi.reducerPath]: fableboxApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      fableboxApi.middleware
    ),
}); 

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

export const prices = {
  digital: 12.99,
  softcover: {
    "md": 24.99,
    "lg": 29.99
  },
  hardcover: {
    "md": 39.99,
    "lg": 44.99
  }
}