
import { forwardRef, ReactElement } from "react";
import { Icon, IconBase, IconWeight } from "@phosphor-icons/react";

const weights = new Map<IconWeight, ReactElement>([
  [
    "regular",
    <>
      <defs>
        <clipPath id="clip1">
          <path d="M 4.304688 141 L 61 141 L 61 196 L 4.304688 196 Z M 4.304688 141 "/>
        </clipPath>
        <clipPath id="clip2">
          <path d="M 193 141 L 249.144531 141 L 249.144531 196 L 193 196 Z M 193 141 "/>
        </clipPath>
        <clipPath id="clip3">
          <path d="M 17 9 L 236 9 L 236 254.542969 L 17 254.542969 Z M 17 9 "/>
        </clipPath>
      </defs>
      <g id="surface1">
        <g clipPath="url(#clip1)" clipRule="nonzero">
        <path stroke="none" fillRule="nonzero" fill="rgb(100%,83.529413%,68.627453%)" fillOpacity={1}  d="M 52.015625 187.820312 C 62.929688 177.210938 62.929688 160.007812 52.015625 149.398438 C 41.097656 138.789062 23.402344 138.789062 12.484375 149.398438 C 1.570312 160.007812 1.570312 177.210938 12.484375 187.820312 C 23.402344 198.433594 41.097656 198.433594 52.015625 187.820312 Z M 52.015625 187.820312 "/>
        </g>
        <g clipPath="url(#clip2)" clipRule="nonzero">
        <path stroke="none" fillRule="nonzero" fill="rgb(100%,83.529413%,68.627453%)" fillOpacity={1} d="M 240.96875 187.820312 C 251.886719 177.210938 251.886719 160.007812 240.96875 149.398438 C 230.054688 138.789062 212.355469 138.789062 201.4375 149.398438 C 190.523438 160.007812 190.523438 177.210938 201.4375 187.820312 C 212.355469 198.433594 230.054688 198.433594 240.96875 187.820312 Z M 240.96875 187.820312 "/>
        </g>
        <g clipPath="url(#clip3)" clipRule="nonzero">
        <path stroke="none" fillRule="nonzero" fill="rgb(100%,83.529413%,68.627453%)" fillOpacity={1} d="M 235.792969 129.789062 C 235.792969 260.242188 161.753906 254.503906 126.722656 254.503906 C 91.691406 254.503906 17.667969 260.242188 17.667969 129.789062 C 17.667969 54.636719 60.605469 9.285156 126.722656 9.285156 C 192.84375 9.285156 235.792969 54.648438 235.792969 129.789062 Z M 235.792969 129.789062 "/>
        </g>
        <path stroke="none" fillRule="nonzero" /* fill="rgb(0%,0%,0%)" */ fillOpacity={1}  d="M 244.574219 153.730469 C 244.574219 153.730469 208.085938 136.773438 185.90625 55.21875 C 185.90625 55.21875 110.6875 160.507812 17.65625 118.976562 L 16.324219 80.640625 C 16.324219 80.640625 38.285156 7 127.585938 7 C 216.890625 7 256.699219 94.925781 244.574219 153.730469 Z M 244.574219 153.730469 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" fillOpacity={1}  d="M 95.773438 185.101562 C 97.546875 182.054688 98.628906 178.574219 98.628906 174.824219 C 98.628906 163.167969 88.917969 153.726562 76.929688 153.726562 C 64.941406 153.726562 55.226562 163.167969 55.226562 174.824219 C 55.226562 178.574219 56.324219 182.054688 58.085938 185.101562 Z M 95.773438 185.101562 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(25.490198%,25.098041%,25.882354%)" fillOpacity={1}  d="M 86.242188 185.105469 C 87.257812 182.101562 87.875 178.597656 87.875 174.824219 C 87.875 163.875 82.976562 155 76.949219 155 C 70.921875 155 66.023438 163.875 66.023438 174.824219 C 66.023438 178.597656 66.640625 182.101562 67.65625 185.105469 L 86.265625 185.105469 Z M 86.242188 185.105469 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" fillOpacity={1}  d="M 195.351562 185.101562 C 197.121094 182.054688 198.207031 178.574219 198.207031 174.824219 C 198.207031 163.167969 188.492188 153.726562 176.503906 153.726562 C 164.515625 153.726562 154.804688 163.167969 154.804688 174.824219 C 154.804688 178.574219 155.898438 182.054688 157.660156 185.101562 Z M 195.351562 185.101562 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(25.490198%,25.098041%,25.882354%)" fillOpacity={1}  d="M 185.8125 185.105469 C 186.828125 182.101562 187.445312 178.597656 187.445312 174.824219 C 187.445312 163.875 182.546875 155 176.515625 155 C 170.488281 155 165.589844 163.875 165.589844 174.824219 C 165.589844 178.597656 166.210938 182.101562 167.222656 185.105469 L 185.835938 185.105469 Z M 185.8125 185.105469 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" fillOpacity={1}  d="M 183.074219 178.929688 C 184.125 178.929688 184.976562 176.398438 184.976562 173.273438 C 184.976562 170.148438 184.125 167.617188 183.074219 167.617188 C 182.023438 167.617188 181.175781 170.148438 181.175781 173.273438 C 181.175781 176.398438 182.023438 178.929688 183.074219 178.929688 Z M 183.074219 178.929688 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" fillOpacity={1}  d="M 82.839844 178.929688 C 83.890625 178.929688 84.742188 176.398438 84.742188 173.273438 C 84.742188 170.148438 83.890625 167.617188 82.839844 167.617188 C 81.789062 167.617188 80.9375 170.148438 80.9375 173.273438 C 80.9375 176.398438 81.789062 178.929688 82.839844 178.929688 Z M 82.839844 178.929688 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(35.686275%,29.019609%,25.098041%)" fillOpacity={1}  d="M 60.414062 141.53125 C 59.75 141.53125 59.085938 141.28125 58.570312 140.792969 C 57.558594 139.808594 57.558594 138.210938 58.570312 137.222656 C 68.191406 127.871094 83.863281 127.859375 93.496094 137.222656 C 94.511719 138.210938 94.511719 139.808594 93.496094 140.792969 C 92.484375 141.78125 90.839844 141.78125 89.824219 140.792969 C 82.222656 133.402344 69.847656 133.414062 62.257812 140.792969 C 61.742188 141.292969 61.078125 141.53125 60.414062 141.53125 Z M 60.414062 141.53125 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(35.686275%,29.019609%,25.098041%)" fillOpacity={1}  d="M 161.785156 141.53125 C 161.121094 141.53125 160.457031 141.28125 159.941406 140.792969 C 158.929688 139.808594 158.929688 138.210938 159.941406 137.222656 C 169.5625 127.871094 185.234375 127.859375 194.867188 137.222656 C 195.882812 138.210938 195.882812 139.808594 194.867188 140.792969 C 193.855469 141.78125 192.210938 141.78125 191.195312 140.792969 C 183.589844 133.402344 171.21875 133.414062 163.628906 140.792969 C 163.113281 141.292969 162.449219 141.53125 161.785156 141.53125 Z M 161.785156 141.53125 "/>
        <path stroke="none" fillRule="nonzero" fill="rgb(89.803922%,65.490198%,49.803922%)" fillOpacity={1}  d="M 126.722656 222.375 C 118.652344 222.375 110.570312 219.394531 104.425781 213.410156 C 103.597656 212.605469 103.597656 211.289062 104.425781 210.484375 C 105.25 209.679688 106.605469 209.679688 107.433594 210.484375 C 118.066406 220.824219 135.375 220.824219 146.007812 210.484375 C 146.835938 209.679688 148.1875 209.679688 149.015625 210.484375 C 149.84375 211.289062 149.84375 212.605469 149.015625 213.410156 C 142.871094 219.382812 134.789062 222.375 126.722656 222.375 Z M 126.722656 222.375 "/>
      </g>
    </>

  ],
]);

const FaceHair: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
));

FaceHair.displayName = "FaceHair";

export default FaceHair;