import styled from "styled-components";


export const StyledLoaderOverlay = styled.div<{$blockHeight?: string}>`
  display: flex;
  align-items: center; 
  justify-content: center;
  flex-direction: column;
  position: relative;
  min-height: ${( {$blockHeight})=> $blockHeight || '80vh' };
`;


export const StyledLoaderText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.6;
  max-width: 10rem;
  font-weight: 700;
  color: inherit;
`;