import { createGlobalStyle } from "styled-components";

import CaveatBold from "./assets/Caveat-Bold.ttf";
import { mediaQuery } from "./utils";

export const colors = {
  red: "#F13E3E",
  black: "#202020",
  darkGray: "#313131",
  gray: "#898989",
  lightGray: "#F5EFFF",
  lightRed: "#E98888",
  whitishRed: "#F9D1D2",
  blue: "#4661E6",
  brightBlue: "#156CF7",
  orange: "#f7be99",
  white: "#FFFFFF",
  lightGreen: "#C1FFD7",
  deepGreen: "#11CC11",
  purple: '#834CFF',
  brightPurple: '#996FF4',
  lightPurple: '#CDC1FF',
  grayPurple: '#E5D9F2',
  yellow: '#fDF7C3',
  golden: '#FFF5DD',
  pink: '#F552D1'
};

const GlobalStyles = createGlobalStyle`
  
  @font-face {
    font-family: 'Caveat';
    src:  url(${CaveatBold}) format('truetype');
    font-weight: 700; 
    font-style: normal; 
  }

  html {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    font-size: 62.5%;
    scroll-behavior: smooth;
    background-image: linear-gradient(238deg, #D5C8FA 14.52%, #D4D6F3 47.98%, #F6D1EC 95.84%);
    color-scheme: light dark;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    color: #263032;
    scrollbar-width: thin;
    scrollbar-color: #996ff4 white;
  }

  *::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.4rem;
  }

  *::-webkit-scrollbar-track {
    background: white;
    border-radius: 1rem;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #996ff4;
    border: 1px solid white;
    border-radius: 1rem;
  }

  body {
    min-height: 99vh;
    background-image: linear-gradient(238deg, #D5C8FA 14.52%, #D4D6F3 47.98%, #F6D1EC 95.84%);
    overflow-x: hidden;
    overflow-y: auto;
  }


  a {
    text-decoration: none;
  }

  .flex {
    display: flex;
    gap: 0.5rem;
  }
  .flex-center {
    align-items: center;
    justify-content: center;
  }
  .layout {
    padding: 2rem 1rem;
    max-width: 140rem;
    margin: 0 auto;
    display: grid;
    gap: 2.5rem;
  }
  ${mediaQuery('lg')}{
    .flex {gap: 1rem}
    .divided {
      display: grid;
      justify-content: center;
      grid-template-columns: 224px 80rem;
    }
  }

  ${mediaQuery("xl")}{
    .divided {
      grid-template-columns: 244px 120rem;
    }
  }
  
`;

export default GlobalStyles;
