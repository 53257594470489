import { StyledButton, StyledHamburger, StyledHeader, StyledHeaderStickyWrapper, StyledLogoLink, StyledMobileButton, StyledNav, StyledNavLink, StyledNavOverlay, StyledNavSection } from "./styled";
import {purpleLogo} from "../../assets/images";
import {Headset, GearSix, SignOut, BookOpenUser, Notification, CardsThree, House, CustomStar} from "../../assets/icons";
import { colors } from "../../GlobalStyles";
import { useState } from "react";
import { useLogoutMutation } from "../../redux/services/fablebox/users/authApi";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/userSlice";
import { userRoles } from "../../types/enum.constants";
import { Link, NavLink, useLocation } from "react-router-dom";


const Header =()=> {
  const location = useLocation();
  const user = useSelector(selectCurrentUser);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [logout] = useLogoutMutation();
  const isAdmin = user?.role === userRoles.ADMIN;
  const isLanding = location.pathname.includes("landing") || !user && location.pathname === "/";

  const closeSideMenu =()=> setIsNavOpen(false);

  return (
    <StyledHeaderStickyWrapper $inApp={!!user && !isLanding} >
      <StyledHeader >
        <StyledHamburger
          color={colors.brightPurple}
          size={32}
          onClick={()=> setIsNavOpen(true)}
          weight="bold"
        />
        
        <StyledNavOverlay $isOpen={isNavOpen} onClick={closeSideMenu}>
          
          <StyledNav $isOpen={isNavOpen} $noUser={!user || isLanding} >
            <NavLink to="/landing">
              <img src={purpleLogo} height={40}/>
            </NavLink>

            {/* Create BUTTON */}
            {!isLanding && user && (
              <StyledNavSection style={{margin: "3rem 0"}}>
                <StyledButton as={Link} to="/create" color="brightPurple" variant="primary">
                  <CustomStar size={25} color="white"/> Create
                </StyledButton>
              </StyledNavSection>
            )}
            
            {/* ADMIN ONLY ITEM (ADMIN TITLE + REVIEW QUEUE ITEM) */}
            {isAdmin && !isLanding && (
              <StyledNavSection>
                <strong>Admin</strong>
                <StyledNavLink to="/books-review" onClick={closeSideMenu}>
                  <Notification size={20} />
                  Review Queue
                </StyledNavLink>
              </StyledNavSection>
            )}

            <StyledNavSection $noUser={!user || isLanding}>
              <strong>Main Menu</strong>
              {user ?
                <>
                  <StyledNavLink to="/dashboard" onClick={closeSideMenu}>
                    <House size={20} />
                    Dashboard
                  </StyledNavLink>
                  <StyledNavLink to="/library" onClick={closeSideMenu}>
                    <BookOpenUser size={20} />
                    My Library
                  </StyledNavLink>
                  <StyledNavLink to="/orders" onClick={closeSideMenu}>
                    <CardsThree size={20} />
                    My Orders
                  </StyledNavLink>
                </>
              :
                <>
                {/* <StyledNavLink to="/testimonials" onClick={closeSideMenu}>
                  Testimonials
                </StyledNavLink> */}
                <StyledNavLink to="/auth/sign-in">
                  Sign in
                </StyledNavLink>
                </>
              }

              {/* Create BUTTON */}
              {(isLanding && !user) && (
                <StyledNavSection>
                  <StyledButton as={Link} color="#834CFF" to='/create' variant="primary">
                    <CustomStar size={25} color="white"/> Create 
                  </StyledButton>
                </StyledNavSection>
              )}
            </StyledNavSection>
            
            {user && (
              <StyledNavSection $noUser={isLanding} style={{ marginTop: 'auto'}}>
                <strong>Settings</strong>
                <StyledNavLink to="/contact" onClick={closeSideMenu}>
                  <Headset  size={20} />
                  Support
                </StyledNavLink>
                <StyledNavLink to="/settings" onClick={closeSideMenu}>
                  <GearSix size={20} />
                  Settings
                </StyledNavLink>
                <StyledNavLink to="#" onClick={()=> logout()} style={{color: 'red'}}>
                  <SignOut size={20} color="red" style={{transform: 'rotate(180deg)'}}/>
                  Logout
                </StyledNavLink>

                {/* Create BUTTON */}
              {isLanding && (
                <StyledNavSection>
                  <StyledButton as={Link} color="brightPurple" to='/create' variant="primary">
                    <CustomStar size={25} color="white" /> Create 
                  </StyledButton>
                </StyledNavSection>
              )}

              </StyledNavSection>
            )}
          </StyledNav>

        </StyledNavOverlay>

        <StyledLogoLink to="/landing">
          <img src={purpleLogo} height={35}/>
        </StyledLogoLink>

        <StyledMobileButton as={Link} to="/create" color="brightPurple" variant="primary">
          <CustomStar size={25} color="white"/> Create
        </StyledMobileButton>

      </StyledHeader>
    </StyledHeaderStickyWrapper>
  );
}

export default Header;