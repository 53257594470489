import { css } from "styled-components";



export const setInterTypo =({
  size,
  lineHeight = 1.3,
  spacing = 0,
  weight = 400
}: {[key: string]: number})=> css`
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-size: ${size}rem;
  line-height: ${lineHeight};
  letter-spacing: ${spacing}rem;
  font-weight: ${weight};
`;



export const setCaveatTypo =({
  size,
  lineHeight = 1.3,
  spacing = 0,
  weight = 400
}: {[key: string]: number})=> css`
  font-family: 'Caveat', Arial, 'Noto Sans', sans-serif;;
  font-size: ${size}rem;
  line-height: ${lineHeight};
  letter-spacing: ${spacing}rem;
  font-weight: ${weight};
`;