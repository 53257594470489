import { StyledLoaderOverlay, StyledLoaderText } from "./styled";

interface LoaderProps {
  text?: string;
  style?: React.CSSProperties;
  size?: number;
  blockHeight?: string;
}

const LoaderBook =({text, style, size = 200}: LoaderProps)=>{
  
  return (
    <StyledLoaderOverlay style={style} >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ shapeRendering: 'auto', display: 'block', background: 'transparent' }}
        width={size}
        height={size}
      >
        <g>
          <path strokeWidth="1" stroke="#702dff" fill="#baa0f3" d="M20 25L80 25L80 75L20 75Z" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            stroke="#702dff"
            fill="#baa0f3"
            d="M50 25L80 25L80 75L50 75"
          >
            <animate
              values="M50 25L80 25L80 75L50 75;M50 25L50 20L50 80L50 75;M50 25L80 25L80 75L50 75;M50 25L80 25L80 75L50 75"
              keyTimes="0;0.5;0.501;1"
              begin="0s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="d"
            />
            <animate
              values="1;1;0;0"
              keyTimes="0;0.5;0.5001;1"
              begin="0s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            />
          </path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            stroke="#702dff"
            fill="#baa0f3"
            d="M50 25L80 25L80 75L50 75"
          >
            <animate
              values="M50 25L80 25L80 75L50 75;M50 25L50 20L50 80L50 75;M50 25L80 25L80 75L50 75;M50 25L80 25L80 75L50 75"
              keyTimes="0;0.5;0.501;1"
              begin="-0.166s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="d"
            />
            <animate
              values="1;1;0;0"
              keyTimes="0;0.5;0.5001;1"
              begin="-0.166s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            />
          </path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            stroke="#702dff"
            fill="#baa0f3"
            d="M50 25L80 25L80 75L50 75"
          >
            <animate
              values="M50 25L80 25L80 75L50 75;M50 25L50 20L50 80L50 75;M50 25L80 25L80 75L50 75;M50 25L80 25L80 75L50 75"
              keyTimes="0;0.5;0.501;1"
              begin="-0.33s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="d"
            />
            <animate
              values="1;1;0;0"
              keyTimes="0;0.5;0.5001;1"
              begin="-0.33s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            />
          </path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            stroke="#702dff"
            fill="#baa0f3"
            d="M50 25L80 25L80 75L50 75"
          >
            <animate
              values="M50 25L20 25L20 75L50 75;M50 25L20 25L20 75L50 75;M50 25L50 20L50 80L50 75;M50 25L20 25L20 75L50 75"
              keyTimes="0;0.499;0.5;1"
              begin="-0.33s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="d"
            />
            <animate
              values="0;0;1;1"
              keyTimes="0;0.4999;0.5;1"
              begin="-0.33s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            />
          </path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            stroke="#702dff"
            fill="#baa0f3"
            d="M50 25L80 25L80 75L50 75"
          >
            <animate
              values="M50 25L20 25L20 75L50 75;M50 25L20 25L20 75L50 75;M50 25L50 20L50 80L50 75;M50 25L20 25L20 75L50 75"
              keyTimes="0;0.499;0.5;1"
              begin="-0.166s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="d"
            />
            <animate
              values="0;0;1;1"
              keyTimes="0;0.4999;0.5;1"
              begin="-0.166s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            />
          </path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            stroke="#702dff"
            fill="#baa0f3"
            d="M50 25L80 25L80 75L50 75"
          >
            <animate
              values="M50 25L20 25L20 75L50 75;M50 25L20 25L20 75L50 75;M50 25L50 20L50 80L50 75;M50 25L20 25L20 75L50 75"
              keyTimes="0;0.499;0.5;1"
              begin="0s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="d"
            />
            <animate
              values="0;0;1;1"
              keyTimes="0;0.4999;0.5;1"
              begin="0s"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            />
          </path>
        </g>
      </svg>
      <StyledLoaderText>
        {text}
      </StyledLoaderText>
    </StyledLoaderOverlay>
  );

}

export default LoaderBook;