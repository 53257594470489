import { forwardRef, ReactElement } from "react";
import { Icon, IconBase, IconWeight } from "@phosphor-icons/react";

const weights = new Map<IconWeight, ReactElement>([
  [
    "regular",
    <g id="surface1">
      <path stroke="none" fillRule="nonzero" /* fill="rgb(90.980393%,75.294119%,61.960787%)" */  d="M 57.386719 186.824219 C 67.703125 175.902344 67.703125 158.199219 57.386719 147.28125 C 47.070312 136.363281 30.339844 136.363281 20.023438 147.28125 C 9.707031 158.199219 9.707031 175.902344 20.023438 186.824219 C 30.339844 197.742188 47.070312 197.742188 57.386719 186.824219 Z M 57.386719 186.824219 "/>
      <path stroke="none" fillRule="nonzero" /* fill="rgb(90.980393%,75.294119%,61.960787%)" */  d="M 235.960938 186.824219 C 246.28125 175.902344 246.28125 158.199219 235.960938 147.28125 C 225.644531 136.363281 208.917969 136.363281 198.601562 147.28125 C 188.28125 158.199219 188.28125 175.902344 198.601562 186.824219 C 208.917969 197.742188 225.644531 197.742188 235.960938 186.824219 Z M 235.960938 186.824219 "/>
      <path stroke="none" fillRule="nonzero" /* fill="rgb(90.980393%,75.294119%,61.960787%)" */  d="M 231.082031 127.101562 C 231.082031 261.34375 161.105469 255.441406 127.996094 255.441406 C 94.886719 255.441406 24.917969 261.34375 24.917969 127.101562 C 24.917969 49.777344 65.5 3.097656 127.996094 3.097656 C 190.488281 3.097656 231.082031 49.777344 231.082031 127.101562 Z M 231.082031 127.101562 "/>
      <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" d="M 98.746094 160.695312 C 100.421875 157.558594 101.449219 153.976562 101.449219 150.117188 C 101.449219 138.125 92.265625 128.410156 80.9375 128.410156 C 69.605469 128.410156 60.425781 138.125 60.425781 150.117188 C 60.425781 153.976562 61.460938 157.558594 63.125 160.695312 Z M 98.746094 160.695312 "/>
      <path stroke="none" fillRule="nonzero" fill="rgb(25.490198%,25.098041%,25.882354%)"  d="M 89.746094 160.691406 C 90.703125 157.601562 91.289062 153.996094 91.289062 150.113281 C 91.289062 138.84375 86.660156 129.710938 80.960938 129.710938 C 75.261719 129.710938 70.632812 138.84375 70.632812 150.113281 C 70.632812 153.996094 71.21875 157.601562 72.175781 160.691406 L 89.769531 160.691406 Z M 89.746094 160.691406 "/>
      <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" d="M 192.863281 160.695312 C 194.539062 157.558594 195.5625 153.976562 195.5625 150.117188 C 195.5625 138.125 186.382812 128.410156 175.050781 128.410156 C 163.722656 128.410156 154.539062 138.125 154.539062 150.117188 C 154.539062 153.976562 155.578125 157.558594 157.242188 160.695312 Z M 192.863281 160.695312 "/>
      <path stroke="none" fillRule="nonzero" fill="rgb(25.490198%,25.098041%,25.882354%)" d="M 183.84375 160.691406 C 184.804688 157.601562 185.386719 153.996094 185.386719 150.113281 C 185.386719 138.84375 180.757812 129.710938 175.058594 129.710938 C 169.363281 129.710938 164.734375 138.84375 164.734375 150.113281 C 164.734375 153.996094 165.316406 157.601562 166.277344 160.691406 L 183.867188 160.691406 Z M 183.84375 160.691406 "/>
      <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" d="M 181.253906 154.339844 C 182.25 154.339844 183.050781 151.734375 183.050781 148.519531 C 183.050781 145.304688 182.25 142.699219 181.253906 142.699219 C 180.265625 142.699219 179.460938 145.304688 179.460938 148.519531 C 179.460938 151.734375 180.265625 154.339844 181.253906 154.339844 Z M 181.253906 154.339844 "/>
      <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" d="M 86.523438 154.339844 C 87.515625 154.339844 88.320312 151.734375 88.320312 148.519531 C 88.320312 145.304688 87.515625 142.699219 86.523438 142.699219 C 85.53125 142.699219 84.726562 145.304688 84.726562 148.519531 C 84.726562 151.734375 85.53125 154.339844 86.523438 154.339844 Z M 86.523438 154.339844 "/>
      <path stroke="none" fillRule="nonzero" fill="rgb(89.803922%,65.490198%,49.803922%)" d="M 128.003906 199.046875 C 120.375 199.046875 112.738281 195.980469 106.929688 189.824219 C 106.148438 188.996094 106.148438 187.640625 106.929688 186.8125 C 107.710938 185.984375 108.992188 185.984375 109.773438 186.8125 C 119.824219 197.449219 136.183594 197.449219 146.234375 186.8125 C 147.015625 185.984375 148.292969 185.984375 149.078125 186.8125 C 149.859375 187.640625 149.859375 188.996094 149.078125 189.824219 C 143.269531 195.96875 135.628906 199.046875 128.003906 199.046875 Z M 128.003906 199.046875 "/>
    </g>
  ],
]);

const Face: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
));

Face.displayName = "Face";

export default Face;