import { forwardRef, ReactElement } from "react";
import { Icon, IconBase, IconWeight } from "@phosphor-icons/react";

const weights = new Map<IconWeight, ReactElement>([
  [
    "regular",
    <>
      <path
        d="M51.8919 16.6029L51.8924 16.6027C54.6268 15.3087 57.3606 14.0937 60.1114 12.9576C61.3531 12.4458 62.7858 12.9843 63.4098 14.1968L100.797 87.0764L50.9049 20.6034C50.9046 20.6031 50.9044 20.6028 50.9042 20.6025C49.8991 19.2491 50.3792 17.3168 51.8919 16.6029Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M28.8222 29.5932L28.8236 29.5924C31.4361 27.8702 34.0576 26.2353 36.6968 24.6702C37.8025 24.0184 39.2113 24.2788 40.0331 25.2757C40.0333 25.276 40.0335 25.2762 40.0337 25.2765L93.8944 91.1316L28.5226 33.8142C28.5224 33.814 28.5222 33.8138 28.522 33.8136C27.2109 32.6547 27.3592 30.551 28.8222 29.5932Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M8.19868 49.5663L8.08215 49.4985H8.0793C6.59472 48.5401 6.45149 46.3816 7.82374 45.237L7.82405 45.2368C10.0645 43.3651 12.3137 41.5545 14.5719 39.7963C15.5264 39.0632 16.8541 39.0831 17.799 39.8427C17.7992 39.8428 17.7993 39.8429 17.7994 39.843L85.5622 94.5498L8.19868 49.5663Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M140.456 87.0218L190.283 20.6025L190.384 20.4686C191.259 19.1361 190.758 17.3111 189.303 16.6208L189.302 16.6208C186.578 15.3273 183.835 14.1123 181.084 12.967C179.842 12.4556 178.41 12.9938 177.786 14.2057L140.456 87.0218ZM140.456 87.0218L177.786 14.2064L140.456 87.0218Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M212.625 33.8214L212.624 33.8222L147.347 91.0918L201.121 25.293C201.121 25.293 201.121 25.293 201.121 25.2929C201.944 24.2874 203.36 24.0283 204.456 24.6789C207.086 26.2439 209.709 27.8879 212.322 29.6022C213.79 30.5715 213.938 32.6748 212.625 33.8214Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M232.967 49.5763L232.91 49.6095L232.902 49.6181L155.809 94.4444L223.358 39.8703C224.305 39.1085 225.633 39.0837 226.585 39.823L226.585 39.8233C228.843 41.5726 231.093 43.3833 233.325 45.2467L233.326 45.2476C234.738 46.4188 234.548 48.6549 232.967 49.5763Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M165.06 7.27695L165.06 7.27691C161.772 6.29824 158.476 5.43325 155.162 4.68189C153.763 4.36475 152.375 5.21194 152.024 6.60396C152.024 6.6041 152.024 6.60424 152.024 6.60437L132.003 86.4075L166.707 10.9281L165.06 7.27695ZM165.06 7.27695C166.595 7.73339 167.381 9.45897 166.707 10.9278L165.06 7.27695Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M138.374 1.80234L138.346 1.79909C135.223 1.44088 132.082 1.17871 128.941 1.00391L138.374 1.80234ZM138.374 1.80234H138.382M138.374 1.80234H138.382M138.382 1.80234C139.905 1.9934 140.959 3.446 140.646 4.96183L140.646 4.96268L124.512 83.8804L126.222 3.58331L126.222 3.58259C126.252 2.10044 127.486 0.923859 128.941 1.00389L138.382 1.80234Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M116.237 255.663L1.95233 193.878L1.95096 193.878C1.04292 193.39 0.5 192.494 0.5 191.531V61.9121C0.5 59.855 2.91045 58.4508 4.90738 59.5215L4.90768 59.5216L114.771 118.327L114.77 118.331C115.687 118.819 116.237 119.724 116.237 120.686V255.663Z"
        fillRule="nonzero"
        strokeWidth={7}
      />
      <path
        d="M125.304 118.273C125.304 118.273 125.304 118.273 125.304 118.273C124.381 118.78 123.832 119.688 123.832 120.648V255.661L239.195 193.307L125.304 118.273ZM125.304 118.273L236.183 57.7465C238.238 56.6265 240.683 58.0206 240.683 60.0862V190.923C240.683 191.89 240.127 192.798 239.196 193.306L125.304 118.273Z"
        fillRule="nonzero"
        strokeWidth={7}
      />
      <path
        d="M109.218 86.3912L74.498 10.9286L74.4973 10.9271C73.8165 9.45993 74.5999 7.73492 76.1439 7.27796L76.1446 7.27774C79.2308 6.3592 82.3243 5.5408 85.4324 4.8225V4.82993L86.043 4.69149C87.4417 4.37434 88.83 5.22156 89.1812 6.61363C89.1813 6.61374 89.1813 6.61386 89.1813 6.61397L109.218 86.3912Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
      <path
        d="M112.234 1.02267L112.234 1.02313C109.093 1.19792 105.97 1.46008 102.847 1.8183L112.234 1.02267ZM112.234 1.02267C113.688 0.947601 114.924 2.11662 114.953 3.60178L114.953 3.60271L116.687 83.7838L100.555 4.98179L100.555 4.98107C100.241 3.45627 101.309 1.99546 102.847 1.81832L112.234 1.02267Z"
        fillRule="nonzero"
        strokeWidth={1}
      />
    </>,
  ],
]);

const CustomOpenBook: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
));

CustomOpenBook.displayName = "CustomOpenBook";

export default CustomOpenBook;
