import { X, CheckCircle, XCircle, CustomOpenBook } from "../../assets/icons";
import { StyledAlertMessage, StyledMessageWrapper } from "./styled";
import { useEffect, useRef, useState } from "react";


interface AlertMessageProps {
  enabled: boolean;
  message: {
    text?: string;
    state: "neutral" | "success" | "error";
  }
  duration?: number;
}

const AlertMessage =({
  message,
  duration = 5000,
  enabled
}: AlertMessageProps)=> {

  const [popAlert, setPopAlert] = useState(enabled);
  const alertRef = useRef<HTMLDivElement>(null);
  const {text = '', state = "neutral"} = message
  
  useEffect(()=> {
    let timerId : number;
    const hideAlertIfNotHovered =()=> {
      if (alertRef.current?.querySelector(":hover") )  { // user reading the message
        clearTimeout(timerId);
        timerId = window.setTimeout(hideAlertIfNotHovered, duration)
      } else {
        setPopAlert(false);
      }
    }
    
    if (enabled && message.text?.length){
      setPopAlert(prevState=> !prevState? true : prevState);
    }
    timerId = window.setTimeout(hideAlertIfNotHovered, duration);
    
    return ()=> clearTimeout(timerId)
  }, [duration, enabled, message.text])
   
  return (
    <StyledMessageWrapper
      $state={state}
      $pop={popAlert}
      ref={alertRef}
    >
      <StyledAlertMessage $state={state}>
        {state === 'success' && <CheckCircle weight="fill" size={35} />}
        {state === 'error' && <XCircle weight="fill" size={35} />}
        {state === 'neutral' && <CustomOpenBook size={32.5} />}

        {text}
      </StyledAlertMessage>
      <X
        size={22}
        onClick={(ev)=> {
          ev.stopPropagation();
          setPopAlert(false)
        }}
        weight="bold"
        color="black"
        className="x"
      />
    </StyledMessageWrapper>
  );
}


export default AlertMessage;