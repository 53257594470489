import { forwardRef, ReactElement } from "react";
import { Icon, IconBase, IconWeight } from "@phosphor-icons/react";

const weights = new Map<IconWeight, ReactElement>([
  [
    "regular",
    <g id="surface1">
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(100%,83.529413%,68.627453%)"  d="M 29.214844 194.675781 C 45.351562 194.675781 58.433594 181.976562 58.433594 166.3125 C 58.433594 150.648438 45.351562 137.949219 29.214844 137.949219 C 13.082031 137.949219 0 150.648438 0 166.3125 C 0 181.976562 13.082031 194.675781 29.214844 194.675781 Z M 29.214844 194.675781 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(100%,83.529413%,68.627453%)"  d="M 226.785156 194.675781 C 242.917969 194.675781 256 181.976562 256 166.3125 C 256 150.648438 242.917969 137.949219 226.785156 137.949219 C 210.648438 137.949219 197.566406 150.648438 197.566406 166.3125 C 197.566406 181.976562 210.648438 194.675781 226.785156 194.675781 Z M 226.785156 194.675781 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(100%,83.529413%,68.627453%)"  d="M 242.035156 125.777344 C 242.035156 261.941406 164.625 255.953125 128 255.953125 C 91.375 255.953125 13.964844 261.941406 13.964844 125.777344 C 13.964844 47.335938 58.863281 0 128 0 C 197.132812 0 242.035156 47.335938 242.035156 125.777344 Z M 242.035156 125.777344 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(100%,100%,100%)"  d="M 78.125 145.628906 C 79.476562 145.628906 80.566406 142.191406 80.566406 137.949219 C 80.566406 133.710938 79.476562 130.273438 78.125 130.273438 C 76.78125 130.273438 75.6875 133.710938 75.6875 137.949219 C 75.6875 142.191406 76.78125 145.628906 78.125 145.628906 Z M 78.125 145.628906 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(100%,100%,100%)"  d="M 112.855469 158.015625 C 116.429688 151.753906 118.632812 144.628906 118.632812 136.933594 C 118.632812 113.050781 98.992188 93.671875 74.792969 93.671875 C 50.589844 93.671875 30.953125 113.035156 30.953125 136.933594 C 30.953125 144.628906 33.167969 151.753906 36.730469 158.015625 Z M 112.855469 158.015625 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} /* fill="rgb(12.941177%,12.941177%,12.941177%)" */  d="M 93.589844 158.015625 C 95.640625 151.859375 96.882812 144.6875 96.882812 136.929688 C 96.882812 114.476562 86.988281 96.289062 74.804688 96.289062 C 62.621094 96.289062 52.730469 114.492188 52.730469 136.929688 C 52.730469 144.6875 53.972656 151.859375 56.023438 158.015625 L 93.621094 158.015625 Z M 93.589844 158.015625 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(100%,100%,100%)"  d="M 215.90625 158.015625 C 219.484375 151.753906 221.683594 144.628906 221.683594 136.933594 C 221.683594 113.050781 202.046875 93.671875 177.84375 93.671875 C 153.640625 93.671875 134.003906 113.035156 134.003906 136.933594 C 134.003906 144.628906 136.21875 151.753906 139.78125 158.015625 Z M 215.90625 158.015625 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} /* fill="rgb(12.941177%,12.941177%,12.941177%)" */  d="M 196.640625 158.015625 C 198.691406 151.859375 199.933594 144.6875 199.933594 136.929688 C 199.933594 114.476562 190.042969 96.289062 177.859375 96.289062 C 165.675781 96.289062 155.78125 114.492188 155.78125 136.929688 C 155.78125 144.6875 157.023438 151.859375 159.074219 158.015625 L 196.671875 158.015625 Z M 196.640625 158.015625 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(100%,100%,100%)"  d="M 83.324219 151.324219 C 86.191406 151.324219 88.515625 143.984375 88.515625 134.933594 C 88.515625 125.882812 86.191406 118.542969 83.324219 118.542969 C 80.457031 118.542969 78.128906 125.882812 78.128906 134.933594 C 78.128906 143.984375 80.457031 151.324219 83.324219 151.324219 Z M 83.324219 151.324219 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(100%,100%,100%)"  d="M 187.585938 151.324219 C 190.453125 151.324219 192.777344 143.984375 192.777344 134.933594 C 192.777344 125.882812 190.453125 118.542969 187.585938 118.542969 C 184.714844 118.542969 182.390625 125.882812 182.390625 134.933594 C 182.390625 143.984375 184.714844 151.324219 187.585938 151.324219 Z M 187.585938 151.324219 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(0%,0%,0%)"  d="M 58.566406 82.78125 C 57.878906 82.78125 57.207031 82.519531 56.679688 81.980469 C 55.632812 80.902344 55.632812 79.164062 56.679688 78.101562 C 66.574219 67.929688 82.679688 67.929688 92.574219 78.101562 C 93.621094 79.179688 93.621094 80.917969 92.574219 81.980469 C 91.523438 83.058594 89.835938 83.058594 88.800781 81.980469 C 80.988281 73.945312 68.265625 73.945312 60.453125 81.980469 C 59.929688 82.519531 59.242188 82.78125 58.566406 82.78125 Z M 58.566406 82.78125 "/>
      <path stroke="none" fillRule="nonzero" fillOpacity={1} fill="rgb(0%,0%,0%)"  d="M 162.769531 82.78125 C 162.082031 82.78125 161.410156 82.519531 160.886719 81.980469 C 159.835938 80.902344 159.835938 79.164062 160.886719 78.101562 C 170.777344 67.929688 186.882812 67.929688 196.777344 78.101562 C 197.824219 79.179688 197.824219 80.917969 196.777344 81.980469 C 195.730469 83.042969 194.039062 83.058594 193.003906 81.980469 C 185.191406 73.945312 172.46875 73.945312 164.65625 81.980469 C 164.132812 82.519531 163.445312 82.78125 162.769531 82.78125 Z M 162.769531 82.78125 "/>
    </g>
  ],
]);

const FaceEyes: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
));

FaceEyes.displayName = "FaceEyes";

export default FaceEyes;